// PCKS_OnFocus
// fires when the user focuses on any of the Paper Editor ckInstances

import store from '@/store';

export default ($opts) => {
	// console.log('PCKS_OnFocus');

	if(window.$vm && window.$vm.emitter){
		window.$vm.emitter.emit('closeAllDropdowns');
		window.$vm.emitter.emit('closeAllFootnoteContextMenus')
		store.commit('paperEdit/footnotes/SET_CONTEXT_MENU_SELECT_ON_CITATION_FIND_BY_ID', '');
	}
	store.commit('paperEdit/ck/SET_EDITOR_LAST_FOCUSED', $opts.editorName);
	
	if (store.state.paperEdit.isPaperFooterOn) {
		window.$vm.emitter.emit('footerUpdateWordCount', {
			editorName: $opts.editorName
		});
	}
}
