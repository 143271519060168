// PApS_CleanUp
// fired when an appendix is added or removed
// mainly it acts as a reset so titles are accurate

import _cloneDeep from 'lodash/cloneDeep'
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import _uniqBy from 'lodash/uniqBy';
import store from '@/store';

export default ($opts) => {
	// console.log('PApS_CleanUp');

	return new Promise((resolve) => {

		// title calculations
		const ordA = 'a'.charCodeAt(0); //97
		const ordZ = 'z'.charCodeAt(0); //122
		const ordAordZlen = ordZ - ordA + 1;

		const _$opts = $opts || {};

		// use what's inPaper if there isn't an array passed
		let _arrayOfAppendices = (_has(_$opts, 'arrayOfAppendices')) ? _$opts.arrayOfAppendices : _cloneDeep(store.state.paperEdit.appendices.inPaper);
		_arrayOfAppendices = _uniqBy(_arrayOfAppendices, 'uid');

		if(_arrayOfAppendices.length === 1){
			_arrayOfAppendices[0].sort = 0;
			_arrayOfAppendices[0].label = 'Appendix';

		} else if(_arrayOfAppendices.length > 1){
			_forEach(_arrayOfAppendices, (appendix, indexCounter)=>{
				// force update sort
				appendix.sort = indexCounter;
			
				let _indexCounter = indexCounter;
				
				// https://stackoverflow.com/questions/8240637/convert-numbers-to-letters-beyond-the-26-character-alphabet
				let labelAppend = "";
				while(_indexCounter >= 0) {
					labelAppend = String.fromCharCode(_indexCounter % ordAordZlen + ordA) + labelAppend;
					_indexCounter = Math.floor(_indexCounter / ordAordZlen) - 1;
				}
				
				// force update a new label
				appendix.label = 'Appendix ' + labelAppend.toUpperCase();
			});//e:forEach
		}
		resolve(_arrayOfAppendices);
	});//e:Promise

}

