// PCKS_NumberFigureCaptions
// Updates the numbers of all figure captions

import _forEach from 'lodash/forEach';
import config from '@/config';
import store from '@/store';

export default ($opts) => {
	return new Promise((resolve) => {
		// console.log('PCKS_NumberFigureCaptions');
		
		if($opts.ckEditor === 'all'){
			// check all instances - this is probably from a document save
			_forEach(CKEDITOR.instances, (ckInstance)=>{
				numberFiguresForSingleInstance(ckInstance);
			});
			
		} else {
			// single instance
			numberFiguresForSingleInstance($opts.ckEditor);

		}//e:if

		return resolve();

	});//e:Promise

	function numberFiguresForSingleInstance(ckEditor){
		let figureCount = 0;

		if(ckEditor.$ckEditorType === config.enums.CkEditorType.ABSTRACT){
			// abstract - start the table count at 0
			figureCount = 0;
			
		} else if(ckEditor.$ckEditorType === config.enums.CkEditorType.BODY){
			// body - i need to know how many tables were in the abstract
			if(CKEDITOR.instances['ckPaperAbstract'] && CKEDITOR.instances['ckPaperAbstract'].document){
				let figuresInAbstract = CKEDITOR.instances['ckPaperAbstract'].document.find('figure');
				figureCount = figuresInAbstract.toArray().length;
			}

		}
		
		if(ckEditor.document){
			// find all table elements in this instance
			let figuresInInstance = ckEditor.document.find('figure');

			// loop through the elements found
			_forEach(figuresInInstance.toArray(), (figureElement)=>{
				// console.log(figureElement);

				figureCount++;

				// find the <figcaption> in this figure
				let thisCaptionEl = figureElement.findOne('figcaption');

				// console.log(thisCaptionEl);
				if(thisCaptionEl){
					// <figcaption> found
					let thisCaptionText = '';

					if(ckEditor.$ckEditorType === config.enums.CkEditorType.APPENDIX){
						thisCaptionText = thisCaptionEl.getText().trim();

					} else {
						if(store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7 || store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
							thisCaptionText = 'Figure ' + figureCount;

						} else if(store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9){
							thisCaptionText = 'Fig. ' + figureCount + '. ';

						}

					}

					let $figureTitleEl = thisCaptionEl.findOne('figure-title');
					if(!$figureTitleEl){
						// figure element wasn't found using custom tag, maybe it's the old span way
						$figureTitleEl = thisCaptionEl.findOne('span.figure-title');
					}
					
					let figureTitle = 'Figure Title';	// default title if the proper element wasn't found
					if($figureTitleEl){
						figureTitle = $figureTitleEl.getHtml();

						if(ckEditor.$ckEditorType === config.enums.CkEditorType.APPENDIX){
							// remove the length of the label from the caption
							figureTitle = figureTitle.slice(0, -figureTitle.length);
						}

					}

					if(store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7){
						thisCaptionEl.setHtml(`
							<figure-caption title="Figure">` + thisCaptionText + `</figure-caption><br/><br/>
							<figure-title>` + figureTitle + `</figure-title>
						`);

					} else if(store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9){
						thisCaptionEl.setHtml(`
							<figure-caption title="Figure">` + thisCaptionText + `</figure-caption>
							<figure-title>` + figureTitle + `</figure-title>
						`);

					} else if(store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
						thisCaptionEl.setHtml(`
							<figure-caption title="Figure">` + thisCaptionText + `.</figure-caption>
							<figure-title>` + figureTitle + `</figure-title>
						`);
						
					}

				}//e:if:thisCaptionEl

			});//e:forEach.figuresInInstance
		}
		
		return;

	}//e:numberFiguresForSingleInstance
}