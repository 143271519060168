// TOCS_AddToPaper

import PS_SaveDocument from '@/services/paper/saveDocument';
import store from '@/store';
import Vue from 'vue';

export default ($opts) => {
	return new Promise((resolve) => {
		// console.log('TOCS_AddToPaper');

		store.commit('paperEdit/meta/SET_IncludeTableOfContents', true);

		PS_SaveDocument({
			isMetaDataChanges: true,
			message: 'Added Table of Contents',
		}).then(()=>{

			Vue.nextTick(()=>{
				// scroll new toc page into view
				let $newInstnaceWrp = document.getElementById('instancePaperToc');
				if($newInstnaceWrp){
					$newInstnaceWrp.scrollIntoView('smooth');
				}

				return resolve();
			});
		});

	});//e:Promise
}
