// PCKS_wProofreaderInit

import _forEach from 'lodash/forEach';
import config from '@/config';
import store from '@/store';

export default ($opts) => {
	return new Promise((resolve) => {
		// console.log('PCKS_wProofreaderInit');
		
		store.commit('paperEdit/ck/SET_IS_WPROOF_READER_ACTIVE', true);

		if(config.useWProofreader && typeof WEBSPELLCHECKER != 'undefined'){
			// WproofReader init
			if($opts.container === 'all'){
				// Find and init all
				_forEach(CKEDITOR.instances, (ckInstance)=>{
					WEBSPELLCHECKER.init({
						container: ckInstance.window.getFrame().$,
					});
				});
			} else {
				// Init single (called in instanceReady)
				WEBSPELLCHECKER.init({
					container: $opts.container,
				});
			}
			return resolve();
			
		} else {
			// WproofReader should not turn on
			return resolve();
		}

	});//e:Promise
}