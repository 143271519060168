/*
- RNS_CalcSortValue
-- When sorting by citation location - a score is calculated and returned
*/
import config from '@/config';

export default ($opts) =>{
	// console.log('RNS_CalcSortValue');
	
	let sortByScore = 0;
	let parsedLocationValue = 0;

	if($opts.sortByEnum === config.enums.ResearchNotesSortBy.CITATION_FIRST_TO_LAST){
		sortByScore = 0;

		// inc sort based on type (location)
		switch($opts.citationData.type){
			case 'n.d.':
				sortByScore += 1000000;
				break;
			case 'page':
				sortByScore += 100000;
				break;
			case 'paragraph':
				sortByScore += 10000;
				break;
			case 'section':
				sortByScore += 1000;
				break;
			case 'table':
				sortByScore += 100;
				break;
			case 'figure':
				sortByScore += 10;
				break;
			// default:
				// don't add anything - it can stay 0
		}
		
		parsedLocationValue = parseInt($opts.citationData.value)
		
		// anything but numbers will comeback as NaN
		if (isNaN(parsedLocationValue)) {
			parsedLocationValue = 0;
		} else {
			parsedLocationValue = 100000 - parsedLocationValue;
		}

		// inc sort base on value
		sortByScore += parsedLocationValue;

	} else if($opts.sortByEnum === config.enums.ResearchNotesSortBy.CITATION_LAST_TO_FIRST){
		sortByScore = 1000000;

		// inc sort based on type (location)
		switch($opts.citationData.type){
			case 'n.d.':
				sortByScore += 10;
				break;
			case 'page':
				sortByScore += 100;
				break;
			case 'paragraph':
				sortByScore += 1000;
				break;
			case 'section':
				sortByScore += 10000;
				break;
			case 'table':
				sortByScore += 100000;
				break;
			case 'figure':
				sortByScore += 1000000;
				break;
			// default:
				// don't add anything - it can stay 0
		}

		parsedLocationValue = parseInt($opts.citationData.value)
		
		// anything but numbers will comeback as NaN
		if (isNaN(parsedLocationValue)) {
			parsedLocationValue = 0;
		}

		// inc sort base on value
		sortByScore += parsedLocationValue;
		
	}
	

	return sortByScore;
		
	
}
