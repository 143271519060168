// PS_RemoveReferenceFromPaper
import _has from 'lodash/has';
import _forEach from 'lodash/forEach';
import PCS_RemoveById from '@/services/paper/citations/removeById';
import PS_UpdateReferenceObject from '@/services/paper/updateReferenceObject';
import RS_GetValueOutput from '@/services/reference/getValueOutput';
import store from '@/store';

export default ($opts) => {
	return new Promise((resolve) => {
		let _reference;

		// do i have this reference included in options?
		if(_has($opts, 'reference')){
			_reference = $opts.reference;
		} else {
			// look this reference up
			_reference = store.state.paperEdit.referencesInPaper.find((reference)=>{
				return reference.referenceID === $opts.referenceID;
			});
		}
		
		// remove this reference from the Reference List
		if(_has($opts, 'paperID')){
			let findPaperIndex = store.state.paperList.list.findIndex((paper) =>{
				return paper.paperID === $opts.paperID;
			});
			if(findPaperIndex != -1){
				// paper found, is this reference already in the list (assume APA7 / Genesis)
				if(store.state.paperList.list[findPaperIndex].genesisReferences.includes($opts.referenceID)){
					store.commit('paperList/REMOVE_REFERENCE_ID_FROM_PAPER', {
						paperIndex: findPaperIndex,
						referenceID: $opts.referenceID
					});
				}
			}
		}

		// force remove active citations from the abstract and body of the paper (user has already confirmed this is ok)			
		if($opts.hasActiveCitations && $opts.isDrawer){
			// console.log('removeReferenceFromPaper - active citations & in drawer');

			// loop through all citations for this reference
			_reference.citations.forEach((citation)=>{
				// group
				if(_has(citation, 'groupUniqueID')){
					_forEach(CKEDITOR.instances, (ckInstance)=>{
						let $citationNodeList = ckInstance.document.find('span[data-group-unique-id="' + citation.groupUniqueID.toUpperCase() + '"]');
						if($citationNodeList){
							// a nodelist was created - convert it toArray
							let $citationNodeListArray = $citationNodeList.toArray();
							if($citationNodeListArray.length > 0){
								_forEach($citationNodeList.toArray(), ($citationNode) => {
									PCS_RemoveById({
										citationElementInPaper: $citationNode,
										referenceIdRemoved: _reference.referenceUniqueID.toUpperCase()
									});
								});//e:forEach
							}//e:if:$citationNodeListArray
						}
					});//e:forEach
				}
			
				// single
				_forEach(CKEDITOR.instances, (ckInstance)=>{
					let $citationNodeList = ckInstance.document.find('span[data-reference-unique-id="' + _reference.referenceUniqueID.toUpperCase() + '"]');
					if($citationNodeList){
						// a nodelist was created - convert it toArray
						let $citationNodeListArray = $citationNodeList.toArray();
						if($citationNodeListArray.length > 0){
							_forEach($citationNodeList.toArray(), ($citationNode) => {
								PCS_RemoveById({
									citationElementInPaper: $citationNode,
								});
							});//e:forEach
						}//e:if:$citationNodeListArray
					}
				});//e:forEach
			});

		}//e:hasActiveCitations

		// remove any left over citations
		// to trigger this:
		// - add a reference, a citation, insert that citation into the paper
		// - now in the organize tab remove this reference from the paper
		store.state.paperEdit.citations.inPaper.forEach((citation)=>{
			if(citation.citationData.referenceUniqueID.toUpperCase() === _reference.referenceUniqueID.toUpperCase()){
				let findCitationIndex = store.state.paperEdit.citations.inPaper.findIndex((citation)=>{
					return citation.citationData.citationUniqueID.toUpperCase() === citation.citationData.citationUniqueID.toUpperCase();
				});
				if(findCitationIndex !== -1){
					store.commit('paperEdit/citations/REMOVE_AT_INDEX', findCitationIndex);
				}

				// remove footnote from vuex store
				if(store.state.paperEdit.config.renderCitationsAsFootnotes){
					store.commit('paperEdit/footnotes/REMOVE_AT_CITATION_UID', citation.citationData.citationUniqueID.toUpperCase());
				}
			}
		});//e:forEach

		// remove reference from this paper
		store.commit('paperEdit/REFERENCE_REMOVE_FROM_PAPER', _reference.referenceUniqueID.toUpperCase());

		let referenceDisplayValue = RS_GetValueOutput({
			displayFormatVersionId: store.state.customer.preferredFormatVersionID,
			referenceData: _reference,
		});

		PS_UpdateReferenceObject({
			saveMessage: 'Removed reference - ' + referenceDisplayValue,
		}).then(()=>{
			return resolve();
		});

	});//e:Promise

}