/*
- PS_DownloadDocumentVersion
-- 2023.03.14
*/

import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import config from '@/config';
import dateToLongString from '@/helpers/date-to-long-string';
import store from '@/store';

export default ($opts) => {
	return new Promise((resolve) => {
		// console.log('PS_DownloadDocumentVersionV4');

		// set opt defaults
		const _$opts = $opts || {};
		const _versionId = (_has(_$opts, 'versionId')) ? _$opts.versionId : '';
		const _isOpenAfter = (_has(_$opts, 'isOpenAfter')) ? _$opts.isOpenAfter : false;
		const _isPrint = (_has(_$opts, 'isPrint')) ? _$opts.isPrint : false;
		const _paperId = (_has(_$opts, 'paperId')) ? _$opts.paperId : store.state.paperEdit.meta.PaperID;
		const _paperTitle = (_has(_$opts, 'paperId')) ? _$opts.paperTitle : store.state.paperEdit.meta.Title;
		let _versionDateOuput = (_has(_$opts, 'versionDateOuput')) ? _$opts.versionDateOuput : null;

		if(!_versionDateOuput){
			_versionDateOuput = dateToLongString({
				dateValue: new Date(),
				showTime: true,
			});
		}
		
		let paramPass = {
			paperId: _paperId,
			platform: $opts.platform,
			contentType: $opts.contentType
		};
		if(_versionId != '') {
			paramPass.versionId = _versionId;
		}

		store.commit('loaders/ADD_ID', 'App');

		ApiS_MakeCall({
			method: 'GET',
			params: paramPass,
			responseType: 'arraybuffer',
			url: 'v4/Paper',
		}).then((responseData)=>{
			let paperTitle = '';

			if(config.platformId === config.enums.Platform.CHROME_EXT){
				if(_isEmpty(store.state.users.papers.openData)){
					paperTitle = _paperTitle || 'Document';
				} else {
					paperTitle = store.state.users.papers.openData.name || 'Document';
				}
			} else {
				paperTitle = _paperTitle || 'Document';
			}

			paperTitle = paperTitle.replace(/[/\\?%*:|"<>]/g, '-');
			
			let fileBlobType = '';
			let fileSaveExtension = '';

			switch($opts.contentType){
				case config.enums.DownloadPaperContentType.COMPRESSED:
					fileBlobType = 'text/plain';
					break;
				case config.enums.DownloadPaperContentType.PDF:
					fileBlobType = 'application/pdf';
					fileSaveExtension = 'pdf';
					break;
				case config.enums.DownloadPaperContentType.HTML:
					fileBlobType = 'text/html';
					fileSaveExtension = 'html';
					break;
				case config.enums.DownloadPaperContentType.WORD:
				case config.enums.DownloadPaperContentType.WORD_SIMPLE:
					fileBlobType = 'application/msword';
					fileSaveExtension = 'docx';
					break;
			}
			
			let file = new Blob([responseData], {
				type: fileBlobType
			});

			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(file, paperTitle + ' - ' + _versionDateOuput + '.' + fileSaveExtension);
			} else {
				let fileURL = window.URL.createObjectURL(file);

				let fileLink = document.createElement('a');
				fileLink.href = fileURL;

				if(_isOpenAfter){
					fileLink.target = '_blank';
					window.open(fileLink);

				} else if(_isPrint){
					const iframe = document.createElement('iframe');
					document.body.appendChild(iframe);

					iframe.style.display = 'none';
					iframe.src = fileURL;
					iframe.onload = ()=>{
						setTimeout(()=>{
							iframe.focus();
							iframe.contentWindow.print();
						}, 33);
					};
					
				} else {
					if($opts.contentType === config.enums.DownloadPaperContentType.COMPRESSED){
						fileLink.download = 'PaperContent.txt';
					} else {
						fileLink.download = paperTitle + ' - ' + _versionDateOuput + '.' + fileSaveExtension;
					}
					document.body.appendChild(fileLink);
					fileLink.click();

					setTimeout(()=>{
						// For Firefox it is necessary to delay revoking the ObjectURL
						window.URL.revokeObjectURL(fileURL);
					}, 100);
				}
				
			}

			store.commit('loaders/REMOVE_ID', 'App');

			resolve();

		});//e:ApiS_MakeCall
	});//e:Promise
}