/*
- PS_RestoreVersion
-- 2023.02.07
*/
 

import _has from 'lodash/has';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import store from '@/store';

export default ($opts) => {
	return new Promise((resolve) => {
		// console.log('PS_RestoreVersion');

		// set opt defaults
		const _$opts = $opts || {};
		const _paperId = (_has(_$opts, 'paperId')) ? _$opts.paperId : '';
		const _versionId = (_has(_$opts, 'versionId')) ? _$opts.versionId : '';

		store.commit('loaders/ADD_ID', 'App');
		
		ApiS_MakeCall({
			url: 'v4/Paper/Restore',
			params:{
				paperId: _paperId,
				versionId: _versionId,
			},
			method: 'PUT',
			responseType: 'none',
		}).then(()=>{
			store.commit('loaders/REMOVE_ID', 'App');
			resolve();
		});//e:ApiS_MakeCall
	});//e:Promise
}
