// TDS_Open

import store from '@/store';

export default () => {
	return new Promise((resolve) => {
		// console.log('TDS_Open');
		
		store.commit('paperEdit/toolDrawer/IS_OPEN', true);
		store.commit('paperEdit/toolDrawer/IS_SIDEMENU_OPEN', false);

		if(window.$vm && window.$vm.emitter){
			window.$vm.emitter.emit('closeAllDropdowns');
		}

		resolve();
	})//e:Promise
}
