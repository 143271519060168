/*
- PCS_InsertCitation
-- Inserts a single citation
-- Renders a citation (APA7 / MLA9) or a Footnote (Turabian)
-- Fires from CitationAdd or the CitationRow to Insert an Orphan Citation
*/

import _has from 'lodash/has';
import config from '@/config';
import PCKS_WrapCitation from '@/services/paper/ck/wrapCitation';
import router from '@/router';
import store from '@/store';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import Vue from 'vue';

export default ($opts) => {
	return new Promise((resolve, reject) => {
		// console.log('PCS_InsertCitation');

		if(!CKEDITOR.instances[store.state.paperEdit.ck.editorLastFocused]){
			return reject();
		}

		let $findFocusedCitation; 

		if(store.state.paperEdit.footnotes.contextMenuSelectedOnFindById === ''){
			// user was already on a citation element in the paper
			$findFocusedCitation = CKEDITOR.instances[store.state.paperEdit.ck.editorLastFocused].document.findOne('span.cke_widget_wrapper_perrla_citation.cke_widget_selected');

		} else {
			// user had a Footnote Citation selected, find it's matching citation in the paper
						
			// start by looking for the group uid
			$findFocusedCitation = CKEDITOR.instances[store.state.paperEdit.ck.editorLastFocused].document.findOne('span[data-group-unique-id="' + store.state.paperEdit.footnotes.contextMenuSelectedOnFindById + '"]');

			if(!$findFocusedCitation){
				// not found, try to find a single
				$findFocusedCitation = CKEDITOR.instances[store.state.paperEdit.ck.editorLastFocused].document.findOne('span[data-citation-unique-id="' + store.state.paperEdit.footnotes.contextMenuSelectedOnFindById + '"]');
			}

			if($findFocusedCitation){
				$findFocusedCitation = $findFocusedCitation.getParent();
			}
		}//e:if:else



		if($findFocusedCitation) {
			// Focus was inside a citation widget while inserting one - combine them

			// %Rh Temp Fix - Group Citations are not wired up in the Outline, stop them from being added for now so you can come back and fix them properly soon
			if(router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
				Swal.fire({
					allowOutsideClick: false,
					buttonsStyling: false,
					text: 'There was a problem adding this citation to your outline',
					icon: 'error',
					confirmButtonText: 'Ok',
					showCloseButton: false,
					showConfirmButton: true,
					customClass:{
						confirmButton: 'btn btn-danger',
					},
				}).then((result) => {
					if (result.value) {
						return reject();
					}
				});//e:Swal

			} else if(router.currentRoute.meta.tab === config.enums.Tab.EDITOR){

				// this is the actual ckElement that is focused
				let $findCitationNode = $findFocusedCitation.findOne('span.perrla_citation');
				
				// console.log('$findCitationNode1');
				// console.log($findCitationNode);

				// https://app.raygun.com/crashreporting/mb3tr7/errors/82499371374
				if(!$findCitationNode) {
					return reject();
				}

				let currentCitationNodeUid = $findCitationNode.getAttribute('data-citation-unique-id');
				// console.log('currentCitationNodeUid');
				// console.log(currentCitationNodeUid);

				// is this citation already part of a group?
				let isGroupCitation = $findCitationNode.hasAttribute('data-group-unique-id');
				
				let newGroupUniqueID = '';
				
				let createOrder = 0;

				if(isGroupCitation) {
					// Already a group citation - add to it
					newGroupUniqueID = $findCitationNode.getAttribute('data-group-unique-id')
					
					let citationsWithThisGroupUid = [];

					// loop through current citations in paper
					store.state.paperEdit.citations.inPaper.forEach((citationObject)=>{
						if(_has(citationObject, 'groupUniqueID') && citationObject.groupUniqueID.toUpperCase() === newGroupUniqueID.toUpperCase()){
							citationsWithThisGroupUid.push(citationObject);
						}
					});

					// console.log('citationsWithThisGroupUid');
					// console.log(citationsWithThisGroupUid);

					createOrder += citationsWithThisGroupUid.length;

				} else {
					// Single citation, create a group
					createOrder++;

					// generate a group uid to link these two inPaper
					newGroupUniqueID = uuidv4().toUpperCase();
					
					// console.log('newGroupUniqueID');
					// console.log(newGroupUniqueID);
					
					$findCitationNode.setAttribute('data-group-unique-id', newGroupUniqueID);
					$findCitationNode.removeAttribute('data-citation-unique-id');

					// console.log('$findCitationNode2');
					// console.log($findCitationNode);

					// add the currently focused citation to the group
					let findCitationIndex = store.state.paperEdit.citations.inPaper.findIndex((citation)=>{
						return citation.citationData.citationUniqueID.toUpperCase() === currentCitationNodeUid.toUpperCase();
					});
					if(findCitationIndex !== -1){
						store.commit('paperEdit/citations/ADD_GROUP_UID_AT_INDEX', {
							createOrder: createOrder,
							findIndex: findCitationIndex,
							groupUniqueID: newGroupUniqueID,
						});
						
						if(store.state.paperEdit.config.renderCitationsAsFootnotes){
							store.commit('paperEdit/footnotes/REMOVE_AT_CITATION_UID', currentCitationNodeUid.toUpperCase());
						}
					}
				}//e:if:else:isGroupCitation

				// add the citation that was just created (or trying to insert as orphan) also to the group
				let findCitationIndex = store.state.paperEdit.citations.inPaper.findIndex((citation)=>{
					return citation.citationData.citationUniqueID.toUpperCase() === $opts.citationUniqueID.toUpperCase();
				});
				if(findCitationIndex !== -1){
					createOrder++;
					store.commit('paperEdit/citations/ADD_GROUP_UID_AT_INDEX', {
						createOrder: createOrder,
						findIndex: findCitationIndex,
						groupUniqueID: newGroupUniqueID,
					});
				}

				// add a group unique id
				$opts.groupUniqueID = newGroupUniqueID;
			
				Vue.nextTick(()=>{
					PCKS_WrapCitation($opts).then(()=>{
						return resolve(newGroupUniqueID);
					});
				});

			}//e:if:else:Tab.EDITOR

		} else {
			// Focus was in a normal document, insert as usual
			if(router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
				CKEDITOR.instances[store.state.paperEdit.ck.editorLastFocused].execCommand('perrlaReferences', {
					startupData: $opts
				});

				Vue.nextTick(()=>{
					PCKS_WrapCitation($opts).then(()=>{
						// if it's a footnote, force space after it so the cursor can grab something 
						if(store.state.paperEdit.config.renderCitationsAsFootnotes){
							let $citationNode = CKEDITOR.instances.ckPaperBody.document.findOne('span[data-citation-unique-id="' + $opts.citationUniqueID.toUpperCase() + '"]');
							if($citationNode && $citationNode.getParent()){
								let textItem = new CKEDITOR.dom.text(' ');
								textItem.insertAfter( $citationNode.getParent() );
							}
						}
						window.$vm.emitter.emit('calculateFootnoteDisplay');
						return resolve($opts.citationUniqueID.toUpperCase());
					});
				});

			} else if(router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
				// console.log('$opts');
				// console.log($opts);
			
				CKEDITOR.instances[store.state.paperEdit.ck.editorLastFocused].execCommand('outlineCitation', {
					startupData: $opts
				});

				Vue.nextTick(()=>{
					store.commit('paperEdit/outline/ADD_CITATION_OBJECT', {
						citationUniqueId: $opts.citationUniqueID,
						dataBase64: $opts.citationDataBase64,
					});

					PCKS_WrapCitation($opts).then(()=>{
						// if it's a footnote, force space after it so the cursor can grab something 
						if(store.state.paperEdit.config.renderCitationsAsFootnotes){
							let $citationNode = CKEDITOR.instances.ckPaperOutline.document.findOne('span[data-citation-unique-id="' + $opts.citationUniqueID.toUpperCase() + '"]');
							
							if($citationNode && $citationNode.getParent()){
								let bracketElement = new CKEDITOR.dom.element('span');
								bracketElement.setHtml('&nbsp;');
								bracketElement.insertAfter( $citationNode.getParent() );
							}
						}
						return resolve();
					});
				});
			}
		}
	});//e:Promise
}