// PS_UpdateReferenceObject
// called after a reference is added or removed

import _has from 'lodash/has';
import config from '@/config';
import PS_BuildReferencesInPaper from '@/services/paper/buildReferencesInPaper';
import PS_SaveDocument from '@/services/paper/saveDocument';
import router from '@/router';

export default ($opts) => {
	return new Promise((resolve) => {
		// console.log('PS_UpdateReferenceObject');

		// set defaults
		const _$opts = $opts || {};
		const _saveMessage = _has(_$opts, 'saveMessage') ? _$opts.saveMessage : '';

		PS_BuildReferencesInPaper().then(()=>{
			let isMatchFound = false;

			if(router.currentRoute.meta.module === config.enums.Module.PAPER_EDIT){
				if(router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
					isMatchFound = true;
					window.$vm.emitter.emit('generateReferencePage', {
						isSaveAfter: true,
						saveMessage: _saveMessage
					});
					return resolve();
				} else if(router.currentRoute.meta.tab === config.enums.Tab.OUTLINE || router.currentRoute.meta.tab === config.enums.Tab.RESEARCH){
					isMatchFound = true;
					PS_SaveDocument({
						isMetaDataChanges: true,
						message: _saveMessage
					}).then(()=>{
						return resolve();
					});
				}
			} else if(router.currentRoute.meta.module === config.enums.Module.REFERENCE_LIST){
				isMatchFound = true;
				return resolve();
			}
			
			if(!isMatchFound){
				console.log('router.currentRoute.name could not find a match');
				console.log(router.currentRoute.name);
			}
		});//e:PS_BuildReferencesInPaper

	});//e:Promise
}