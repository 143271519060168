// PAnS_AddUpdate
// Adds an empty annotation instnace
// Updates exisiting annotation content

import store from '@/store';

export default ($opts) => {
	// console.log('PAnS_AddUpdate');

	return new Promise((resolve) => {
		store.commit('paperEdit/annotations/IS_INCLUDE', true);
				
		let thisAnnotationFindIndex = store.state.paperEdit.annotations.inPaper.findIndex((annotationData)=>{
			return annotationData.referenceUniqueId.toUpperCase() === $opts.referenceUniqueId.toUpperCase();
		});
		if(thisAnnotationFindIndex > -1){
			store.commit('paperEdit/annotations/UPDATE_AT_INDEX', {
				content: $opts.content,
				index: thisAnnotationFindIndex,
			});
		} else {
			// Add
			store.commit('paperEdit/annotations/ADD_TO_PAPER', $opts);
		}

		return resolve();
	});//e:Promise			

}
