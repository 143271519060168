

// PCKS_wProofreaderDestroy
import config from '@/config';
import store from '@/store';

export default () => {
	return new Promise((resolve) => {
		// console.log('PCKS_wProofreaderDestroy');
		
		if(config.useWProofreader && typeof WEBSPELLCHECKER != 'undefined'){

			// get all wsc instances (should be one on every ckInstance)
			let wscInstances = WEBSPELLCHECKER.getInstances();
					
			// loop through instance
			wscInstances.forEach((wscInstance)=>{
				wscInstance.destroy();
			});
		}

		store.commit('paperEdit/ck/SET_IS_WPROOF_READER_ACTIVE', false);
		
		resolve();
	});//e:Promise
}
