// PS_LoadByRoute
import _isEmpty from 'lodash/isEmpty';
import AS_SyncData from '@/services/app/syncData';
import store from '@/store';

export default () =>{
	return new Promise((resolve, reject) => {
		// console.log('PS_LoadByRoute');

		if(_isEmpty(store.state.openObject)){
			// console.log('need to look this paper up');

			AS_SyncData().then(()=>{
				// look paper up by the encryptedId param
				let findPaper = store.state.paperList.list.find((paperData)=>{
					return paperData.encryptedID === window.$vm.$route.params.encryptedId
				});

				if(findPaper){
					store.commit('paperEdit/SET_OPEN_OBJECT', findPaper)
					resolve();
				} else {
					reject('Could not find paper');
				}
			});//e:syncData

		} else {
			store.commit('loaders/REMOVE_ID', 'App');
			
			resolve();
		}

	});//e:Promise

}