
// PCS_RemoveById
// removes a citation (single or grouped) - it loops after removing a reference from this paper

import _has from 'lodash/has';
import config from '@/config';
import store from '@/store';

export default ($opts) => {
	return new Promise((resolve) => {
		// console.log('PCS_RemoveById');

		let saveMessage = '';	// resolves back so SaveDocument has a message of what was deleted

		let isGroupCitation = false;

		if($opts.citationElementInPaper){
			isGroupCitation = $opts.citationElementInPaper.hasAttribute('data-group-unique-id');
		}

		if(isGroupCitation){
			// Group
			let groupUniqueId = $opts.citationElementInPaper.getAttribute('data-group-unique-id');
			// console.log('groupUniqueId');
			// console.log(groupUniqueId);

			let remainingCitationObject;

			// get a list of single citations inside this group id
			let listOfCitationsInGroup = store.state.paperEdit.citations.inPaper.filter((citationData)=>{
				return (citationData.groupUniqueID) && (citationData.groupUniqueID.toUpperCase() === groupUniqueId.toUpperCase());
			});
			// console.log('listOfCitationsInGroup');
			// console.log(listOfCitationsInGroup);

			// loop through single citations in this group
			listOfCitationsInGroup.forEach((citationObject)=>{
				if(
					_has(citationObject,'citationData') && 
					_has(citationObject.citationData, 'referenceUniqueID')
				){
					if(citationObject.citationData.referenceUniqueID === $opts.referenceIdRemoved){
						// remove citation NOT from the matching reference from vuex store citationInPaperObject
						let findCitationIndex = store.state.paperEdit.citations.inPaper.findIndex((citation)=>{
							return citation.citationData.citationUniqueID.toUpperCase() === citationObject.citationData.citationUniqueID.toUpperCase();
						});
						if(findCitationIndex !== -1){
							store.commit('paperEdit/citations/REMOVE_AT_INDEX', findCitationIndex);
						}
					} else {
						remainingCitationObject = citationObject;
					}
					
				}
			});//e:forEach
			
			// update citation widget in paper body
			if (remainingCitationObject && $opts.citationElementInPaper && $opts.citationElementInPaper.getParent()) {
				saveMessage = $opts.citationElementInPaper.getHtml();
				// console.log(saveMessage);

				delete remainingCitationObject.groupUniqueID;
				// console.log('remainingCitationObject');
				// console.log(remainingCitationObject);

				$opts.citationElementInPaper.removeAttribute('data-group-unique-id');
				$opts.citationElementInPaper.setAttribute('data-citation-unique-id', remainingCitationObject.citationData.citationUniqueID.toUpperCase());
				$opts.citationElementInPaper.setAttribute('data-reference-unique-id', remainingCitationObject.citationData.referenceUniqueID.toUpperCase());
										
				// prob should check here if apa7 or mla9 when i have all this working 
				$opts.citationElementInPaper.setHtml(remainingCitationObject.apa7.first);

			} else {
				console.log('There was a problem removing the citation from this paper');
			}


		} else {
			// Single
			let citationUniqueId = $opts.citationElementInPaper.getAttribute('data-citation-unique-id');
			// console.log(citationUniqueId);

			// remove citation from vuex store citationInPaperObject
			let findCitationIndex = store.state.paperEdit.citations.inPaper.findIndex((citation)=>{
				return citation.citationData.citationUniqueID.toUpperCase() === citationUniqueId.toUpperCase();
			});
			if(findCitationIndex !== -1){
				store.commit('paperEdit/citations/REMOVE_AT_INDEX', findCitationIndex);
			}

			// remove citation widget from paper body
			if ($opts.citationElementInPaper && $opts.citationElementInPaper.getParent()) {
				saveMessage = $opts.citationElementInPaper.getHtml();
				// console.log(saveMessage);
				
				if(store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
					let $elToInsert = new CKEDITOR.dom.element('sup');
					$elToInsert.setText('CITATION DELETED');
					$elToInsert.replace($opts.citationElementInPaper.getParent());

				} else {
					let $elToInsert = new CKEDITOR.dom.element('span');
					
					if(store.state.paperEdit.meta.UseNewCitationMode){
						$elToInsert.setText('CITATION DELETED');
					} else {
						$elToInsert.setText('(CITATION DELETED)');
					}
					$elToInsert.replace($opts.citationElementInPaper.getParent());

				}
				

				
			} else {
				console.log('There was a problem removing the citation from this paper');
			}

		}

		resolve(saveMessage);
		
	});//e:Promise
}
