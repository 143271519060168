// PCKS_NumberTableCaptions

import _forEach from 'lodash/forEach';
import config from '@/config';
import store from '@/store';

export default ($opts) => {
	return new Promise((resolve) => {
		// console.log('PCKS_NumberTableCaptions');
		
		if($opts.ckEditor === 'all'){
			// check all instances - this is probably from a document save
			_forEach(CKEDITOR.instances, (ckInstance)=>{
				numberTablesForSingleInstance(ckInstance);
			});
			
		} else {
			// single instance
			numberTablesForSingleInstance($opts.ckEditor);

		}//e:if
		
		return resolve();

	});//e:Promise

	function numberTablesForSingleInstance(ckEditor){
		let tableCount = 0;

		if(ckEditor.$ckEditorType === config.enums.CkEditorType.ABSTRACT){
			// abstract - start the table count at 0
			tableCount = 0;
			
		} else if(ckEditor.$ckEditorType === config.enums.CkEditorType.BODY){
			// body - i need to know how many tables were in the abstract
			if(CKEDITOR.instances['ckPaperAbstract'] && CKEDITOR.instances['ckPaperAbstract'].document){
				let tablesInAbstract = CKEDITOR.instances['ckPaperAbstract'].document.find('table');
				tableCount = tablesInAbstract.toArray().length;
			}

		}

		// find all table elements in this instance
		if(ckEditor.document){
			let tablesInInstance = ckEditor.document.find('table');

			// loop through the elements found
			_forEach(tablesInInstance.toArray(), (tableElement)=>{
				tableElement.$.style.width = "100%";
				tableCount++;

				// find the <caption> in this table
				let thisCaptionEl = tableElement.findOne('caption');

				if(thisCaptionEl){
					// <caption> found
					let thisCaptionText = '';

					if(ckEditor.$ckEditorType === config.enums.CkEditorType.APPENDIX){
						thisCaptionText = thisCaptionEl.getText().trim();
					} else {
						thisCaptionText = 'Table ' + tableCount;
					}

					let $tableTitleEl = thisCaptionEl.findOne('table-title');
					if(!$tableTitleEl){
						// table title element wasn't found using custom tag, maybe it's the old span way
						$tableTitleEl = thisCaptionEl.findOne('span.table-title');
					}

					let tableTitle = 'Table Title';	// default title if the proper element wasn't found
					if($tableTitleEl){
						tableTitle = $tableTitleEl.getHtml();

						if(ckEditor.$ckEditorType === config.enums.CkEditorType.APPENDIX){
							// remove the length of the label from the caption
							tableTitle = tableTitle.slice(0, -tableTitle.length);
						}

					}

					if(store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
						thisCaptionEl.setHtml(`
							<table-caption title="Table">` + thisCaptionText + `.</table-caption>
							<table-title>` + tableTitle + `</table-title>
						`);
					} else {
						thisCaptionEl.setHtml(`
							<table-caption title="Table">` + thisCaptionText + `</table-caption><br/><br/>
							<table-title>` + tableTitle + `</table-title>
						`);
					}
				}//e:if:thisCaptionEl

				// https://trello.com/c/aa1Wc0xN/201-tfoot-element-causing-save-error
				// check this tables footer <tfoot>
				let tFootElements = tableElement.$.getElementsByTagName("tfoot");
				if (tFootElements.length > 0) {
					// now you have a tfoot, look for any td in side
					let tFootTdElement = tFootElements[0].getElementsByTagName("td");
					if(tFootTdElement.length === 0){
						// there is a tfoot without a td, remove the whole tfoot
						tFootElements[0].remove();
					}
				}
			});//e:forEach.tableElement
		}//e:if

		return;
	}//e:numberTablesForSingleInstance

}