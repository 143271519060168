/*
- PS_BuildReferencesInPaper
-- looks at the unique reference ids inside a paper and builds a local reference object
-- also cleans the citation objects (collapsed function at end)
*/

import _cloneDeep from 'lodash/cloneDeep';
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import _orderBy from 'lodash/orderBy';
import _sortBy from 'lodash/sortBy';
import config from '@/config';
import Engine from '@/services/reference/Engine';
import router from '@/router';
import RS_CalcCitationDisplayValueFromReferenceData from '@/services/reference/calcCitationDisplayValueFromReferenceData';
import RS_ExtractPrimaryTitleFromReferenceData from '@/services/reference/extractPrimaryTitleFromReferenceData';
import store from '@/store';

export default () => {
	return new Promise((resolve) => {
		// console.log('PS_BuildReferencesInPaper');
		
		let _referencesInPaper = [];

		// console.log('store.state.paperEdit.referenceUniqueIdsInPaper');
		// console.log(store.state.paperEdit.referenceUniqueIdsInPaper);

		if(store.state.paperEdit.referenceUniqueIdsInPaper.length === 0){
			// no references
			store.commit('paperEdit/SET_REFERENCES_IN_PAPER', []);

			window.$vm.emitter.emit('referencesInPaperReady');

			return resolve();
			
		} else {

			// loop through 'references' (value will be a Unique ID)
			store.state.paperEdit.referenceUniqueIdsInPaper.forEach((referenceUID)=>{
				// look up a fresh copy of the reference by using the UID from the paper content
				let thisReference = store.state.referenceLibraryGenesis.find((reference)=>{
					return reference.referenceUniqueID.toUpperCase() === referenceUID.toUpperCase();
				});
				
				if(thisReference){
					// console.log('thisReference');
					// console.log(thisReference);
				
					let _citationsForThisReference = [];

					store.state.paperEdit.citations.inPaper.forEach((citation)=>{

						if(citation.citationData.referenceUniqueID.toUpperCase() === referenceUID.toUpperCase()){
							let _thisCitation = _cloneDeep(citation);
							
							// console.log('_thisCitation');
							// console.log(_thisCitation);

							// loop through each CkEditor Instance
							_forEach(CKEDITOR.instances, (ckInstance)=>{
								if(ckInstance.document){
									// i need the actual citation widget node so i can add it's position in the document

									let $citationNode = ckInstance.document.findOne('span[data-group-unique-id="' + _thisCitation.groupUniqueID + '"]');

									if($citationNode){
										// group citation
										$citationNode.removeAttribute('data-citation-unique-id');
										$citationNode.removeAttribute('data-reference-unique-id');

									} else {
										// no group citation - look for a single
										$citationNode = ckInstance.document.findOne('span[data-citation-unique-id="' + _thisCitation.citationData.citationUniqueID + '"]');

										if($citationNode){
											// single citation found
											$citationNode.removeAttribute('data-group-unique-id');
											$citationNode.setAttribute('data-reference-unique-id', _thisCitation.citationData.referenceUniqueID.toUpperCase());
										}

									}

									if($citationNode){
										// console.log('$citationNode');
										// console.log($citationNode);

										let weightForEditor = 0;

										// i need to weight the document position based on what ckInstance it's in
										// if(ckInstance.name === 'ckPaperBody'){
										// 	weightForEditor = 100;
										// }
									
										_thisCitation.documentPosition = Math.round($citationNode.getDocumentPosition().y) + (Math.round($citationNode.getDocumentPosition().x) / 1000) + weightForEditor;

										return false;	// stop looping through ck instances i found what i needed


									} else {
										_thisCitation.documentPosition = 0;
									}

								}//e:ckInstance.document

							});//e:forEach

							// console.log('_thisCitation.documentPosition');
							// console.log(_thisCitation.documentPosition);

							_citationsForThisReference.push(_thisCitation);

						}//e:if
					});//e:_forEach:paperEdit.citations.inPaper

					_citationsForThisReference = _sortBy(_citationsForThisReference, 'documentPosition');
					
					// console.log('_citationsForThisReference');
					// console.log(_citationsForThisReference);

					// prepare the new modified reference
					let thisReferenceModified = _cloneDeep(thisReference); // clone the array so the Engine can modify it
					
					// console.log('thisReferenceModified');
					// console.log(thisReferenceModified);

					if(router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
						thisReferenceModified.citations = _citationsForThisReference;
						
						if(store.state.customer.citationAutoInsert){
							thisReferenceModified.citationDisplayValue = RS_CalcCitationDisplayValueFromReferenceData(thisReference) || '';
							thisReferenceModified.primaryTitle = RS_ExtractPrimaryTitleFromReferenceData(thisReference.data) || '';
						}
					

					} else if(router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
						store.state.paperEdit.referencesInPaper.forEach((referenceObject)=>{
							if(thisReferenceModified.referenceUniqueID.toUpperCase() === referenceObject.referenceUniqueID.toUpperCase()){
								if(_has(referenceObject, 'citations') && referenceObject.citations.length > 0){
									thisReferenceModified.citations = referenceObject.citations;
								}
								
								if(store.state.customer.citationAutoInsert){
									thisReferenceModified.citationDisplayValue = RS_CalcCitationDisplayValueFromReferenceData(thisReference) || '';
									thisReferenceModified.primaryTitle = RS_ExtractPrimaryTitleFromReferenceData(thisReference.data) || '';
								}
							}
						});//forEach:paperEdit.referencesInPaper

					}//e:if:else:Tab.OUTLINE
					
					_referencesInPaper.push(thisReferenceModified);

				}//e:if:thisReference
				
			});//e:forEach:paperEdit.referenceUniqueIdsInPaper
			
			// console.log(_referencesInPaper);
			
			// run the special rules Engine (this will check in a full complete List of Reference Objects in this paper)
			// try it first an stop if there is an error
			let EngineAfterSpecialRules;
			try {
				EngineAfterSpecialRules = Engine.specialRules(store.state.paperEdit.meta.PaperFormatVersionID, _referencesInPaper);
				// EngineAfterSpecialRules = Engine.specialRules(store.state.paperEdit.meta.PaperFormatVersionID); // this will cause an error
				// console.log('Special Rules were a success');
				// console.log(EngineAfterSpecialRules);
			} catch (error){
				console.log('Special Rules had an error');
				
				console.log(error);
				return;	// since there was an error stop here, the blue spinner is still up - this is a chance to communicate to them something is wrong, or support can see more details if they are impersonating 
			}

			store.commit('paperEdit/SET_REFERENCES_IN_PAPER', EngineAfterSpecialRules);

			window.$vm.emitter.emit('referencesInPaperReady');


			/*
			*** PCKS_CleanCitationOutput
			*/
			if(router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
				if(store.state.paperEdit.config.renderCitationsAsFootnotes){
					// footnotes
					let footnoteCounter = 1;

					// loop through each CkEditor Instance
					_forEach(CKEDITOR.instances, (ckInstance)=>{
						if(ckInstance.document){
							let citationElementsInThisInstance = ckInstance.document.find('span.perrla_citation');
							
							// loop through any citations element found in this instance
							_forEach(citationElementsInThisInstance.toArray(), (citationElement)=>{
								
								
								let citationElementParent = citationElement.getParent();
													
								// Add a space after this footnote so the cursor can grab something
								if(citationElementParent){
								
									citationElement.setHtml('<sup>' + footnoteCounter + '</sup>');
									footnoteCounter++;

									let citationAfter = citationElementParent.getNext();

									if(!citationAfter){
										// there is nothing after this citation
										let bracketElement = new CKEDITOR.dom.element('span');
										bracketElement.setHtml('&nbsp;');
										bracketElement.insertAfter(citationElementParent);
									}//e:if:!citationAfter

								

								}//e:if:citationElementParent
								
							});//e:forEach
						}//e:ckInstance.document
					}); //e:forEach
					
					window.$vm.emitter.emit('calculateFootnoteDisplay');
					
				} else {
					// regular citations
					let citationsWithDocumentPosition = []; // list of all the citations with a document position that isn't 0
	
					// loop through references in this paper
					store.state.paperEdit.referencesInPaper.forEach((referenceObject)=>{
						if(_has(referenceObject, 'citations') && referenceObject.citations.length > 0){
							referenceObject.citations.forEach((citationObject)=>{
								if(_has(citationObject, 'documentPosition') && citationObject.documentPosition !== 0){
									citationsWithDocumentPosition.push(citationObject);
								}
							});//e:forEach:referenceObject.citations
						}//e:if
					});//forEach:paperEdit.referencesInPaper
	
					// citationsWithDocumentPosition should be built now
					if(citationsWithDocumentPosition.length > 0){
						citationsWithDocumentPosition = _sortBy(citationsWithDocumentPosition, ['documentPosition']);
	
						citationsWithDocumentPosition.forEach((citationObject)=>{
							// find the citation node in the ckEditor instances and force it's content to come from the Engine
							let $citationNode = null;
							
							_forEach(CKEDITOR.instances, (ckInstance)=>{
								// look for a group citation first
								if(citationObject.groupUniqueID){
									$citationNode = ckInstance.document.findOne('span[data-group-unique-id="' + citationObject.groupUniqueID.toUpperCase() + '"]');
								}
								if($citationNode){
									return false;	// stop looking when found
								} else {
									// look for regular citations second
									$citationNode = ckInstance.document.findOne('span[data-citation-unique-id="' + citationObject.citationData.citationUniqueID.toUpperCase() + '"]');
									if($citationNode){
										return false;	// stop looking when found
									}
								}
							});//e:forEach:ckInstance
	
							if($citationNode){
								// Not a footnote - treat like a regular citation
								// check if using new citation mode
								if(store.state.paperEdit.meta.UseNewCitationMode){
									// console.log('citationObject');
									// console.log(citationObject);
									$citationNode.setHtml(citationObject.displayValue.slice(1, -1));
			
								} else {
									$citationNode.setHtml(citationObject.displayValue);
			
								}//e:if:UseNewCitationMode
							}//e:if:$citationNode
	
						});//e:forEach:citationsWithDocumentPosition
	
					}//e:if:citationsWithDocumentPosition.length
	
				}//e:if:else:renderCitationsAsFootnotes
				
			} else if(router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
				let citationsInThisOutline;
				if(store.state.paperEdit.config.renderCitationsAsFootnotes){
					citationsInThisOutline = [];
	
				} else {
					citationsInThisOutline = {};
				}
	
				// loop trough all ck instances on this page
				_forEach(CKEDITOR.instances, (ckInstance)=>{
					// find any citations in this ck instance
					let citationsNodeList = ckInstance.document.find('span.perrla_citation');
					// console.log(citationsNodeList);
	
					// loop through all citation elements - i'm going to group them by reference unique id
					_forEach(citationsNodeList.toArray(), (citationElement, index)=>{
						// console.log('citationElement');
						// console.log(citationElement);
	
						// parse the data object (this only works this way when coming from the outline - if you make this broader you will have to accomodate)
						try {
							let citationDataObject = JSON.parse(window.atob(citationElement.getAttribute('citation-data')));
							// console.log('citationDataObject');
							// console.log(citationDataObject);
							
							// add this reference uid 
							if(store.state.paperEdit.config.renderCitationsAsFootnotes){
								// console.log('make an array of all citations');
								citationsInThisOutline.push({
									citationDataObject: citationDataObject,
									citationElement: citationElement,
									footnoteIndex: index,
								});
	
							} else {
								if(!_has(citationsInThisOutline, citationDataObject.citationData.referenceUniqueID)){
									citationsInThisOutline[citationDataObject.citationData.referenceUniqueID] = [];
								}
				
								// push citation data here
								citationsInThisOutline[citationDataObject.citationData.referenceUniqueID].push({
									citationDataObject: citationDataObject,
									citationElement: citationElement,
									footnoteIndex: index,
								});
							}
						} catch(error){
							console.log('error JSON.parse(window.atob');
							console.log(error);
						}//e:try:catch
					
					});//e:forEach:citationsNodeList.toArray() 
	
				});//e:forEach:CKEDITOR.instances
	
				// all ck instances have been looped through - a super array of citations on this page is now ready
	
				if(store.state.paperEdit.config.renderCitationsAsFootnotes){
					
					let _citationsInThisOutline = _orderBy(citationsInThisOutline, ['footnoteIndex'], ['asc']);
	
					_forEach(_citationsInThisOutline, (citationDataChild, index)=>{
						// console.log('citationDataChild');
						// console.log(citationDataChild);
	
						// Footnote content
						citationDataChild.citationElement.setHtml('<sup>' + (index + 1) + '</sup>');
						
						// Add a space after this footnote so the cursor can grab something
						if(citationDataChild.citationElement.getParent()){
							let citationAfter = citationDataChild.citationElement.getParent().getNext();
							if(!citationAfter){
								// there is nothing after this citation
								let bracketElement = new CKEDITOR.dom.element('span');
								bracketElement.setHtml('&nbsp;');
								bracketElement.insertAfter( citationDataChild.citationElement.getParent() );
							}
						}
	
					});//e:forEach:citationDataChidren
	
					store.commit('paperEdit/outline/SET_FULL_CITATION_OBJECTS', _citationsInThisOutline);
	
				} else {
					
					// console.log('citationsInThisOutline');
					// console.log(citationsInThisOutline);

					// loop through each citation in the super array
					_forEach(citationsInThisOutline, (citationsForThisReference, referenceUniqueId)=>{
						// sort these citations by their position
						let _citationsForThisReference = _orderBy(citationsForThisReference, ['footnoteIndex'], ['asc']);
						
						// loop through each citation object found under this reference uid
						_forEach(_citationsForThisReference, (citationDataChild, index)=>{
							if(store.state.paperEdit.config.renderCitationsAsFootnotes){
								// Footnote content
								citationDataChild.citationElement.setHtml('<sup>' + (index + 1) + '</sup>');
	
							} else {
								// Not a footnote - treat like a regular citation

								// use this referenceUniqueId to look up the full reference in the library - i want real ref data
								let thisReference = store.state.referenceLibraryGenesis.find((ref) => {
									return ref.referenceUniqueID.toUpperCase() == referenceUniqueId.toUpperCase();
								});

								if(thisReference){
									let modeLevel = 'available';
									if(config.isDev){
										modeLevel = 'dev';
					
									} else if(config.isTest){
										modeLevel = 'test';
										
									}
									
									let citationEngineResult = Engine.getCitation(thisReference.referenceTypeID, JSON.parse(thisReference.data), citationDataChild.citationDataObject.citationData, modeLevel);

									let setValue = '';
		
									if(store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7){
										// APA7
										if(index === 0){
											setValue = citationEngineResult.apa7.first;
			
										} else {
											setValue = citationEngineResult.apa7.subsequent;
											if(setValue === ''){
												setValue = citationEngineResult.apa7.first;
											}
			
										}
										
									} else if(store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9){
										// MLA9
										if(index === 0){
											setValue = citationEngineResult.mla9.first;
			
										} else {
											setValue = citationEngineResult.mla9.subsequent;
											if(setValue === ''){
												setValue = citationEngineResult.mla9.first;
											}
			
										}
			
									} else if(store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
										// Turabian9
										if(index === 0){
											setValue = citationEngineResult.turabian9.first;
			
										} else {
											setValue = citationEngineResult.turabian9.subsequent;
											if(setValue === ''){
												setValue = citationEngineResult.turabian9.first;
											}
			
										}
			
									}//e:if

									if(store.state.paperEdit.meta.UseNewCitationMode){
										citationDataChild.citationElement.setHtml(setValue.slice(1, -1));
									} else {
										citationDataChild.citationElement.setHtml(setValue);
									}//e:if:UseNewCitationMode

								}//e:if:thisReference

							}//e:if:store.state.paperEdit.config.renderCitationsAsFootnotes
	
						});//e:forEach:citationDataChidren
	
					});//e:forEach:citationsInThisOutline
	
				}//e:store.state.paperEdit.config.renderCitationsAsFootnotes
				
			}//e:if:OUTLINE
			
			return resolve();
				
		}//e:if:else
		
	});//e:Promise
}