// PS_InjectContent
import config from '@/config';
import LzString from 'lz-string';
import PS_ParseHiddenTags from '@/services/paper/parseHiddenTags';
import RS_UpdateAfterSync from '@/services/reference/updateAfterSync';
import store from '@/store';

export default ($opts) => {
	return new Promise((resolve) => {
		// console.log('PS_InjectContent');

		store.commit('paperEdit/SET_ENCRYPTED_PAPER_LZ_STRING', $opts.compressedPaperData);

		let decompressedPaperData = LzString.decompressFromUTF16($opts.compressedPaperData);

		PS_ParseHiddenTags(decompressedPaperData).then(()=>{
			switch(store.state.paperEdit.meta.DocumentType){
				case config.enums.DocumentType.ANNOTATED_BIBLIOGRAPHY:
				case config.enums.DocumentType.REFERENCE_LIST:
					// no ckEditor Body
					RS_UpdateAfterSync().then(()=>{
						window.$vm.emitter.emit('loadCkReferencePage');
						store.commit('loaders/REMOVE_ID', 'App');
					});
					break;
				case config.enums.DocumentType.DISCUSSION_POST:
					// no Title Page
					window.$vm.emitter.emit('loadCkBody');
					break;
				case config.enums.DocumentType.RESEARCH_PAPER:
					window.$vm.emitter.emit('loadCkTitlePage');
					window.$vm.emitter.emit('loadTitlePageDrawer');
					break;
			}

			store.commit('paperEdit/ck/SET_IS_EDITOR_LOADED_ONCE');

			resolve();

		});//e:paperEdit/parseHiddenTags

	});//e:Promise

}