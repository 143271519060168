// PApS_AddToPaper
import config from '@/config';
import PApS_CleanUp from '@/services/paper/appendices/cleanUp';
import store from '@/store';
import { v1 as uuidv1 } from 'uuid';

export default () => {
	// console.log('PApS_AddToPaper');

	return new Promise((resolve) => {
		// generate a uid for this appendix you are adding
		let newUUID = uuidv1().toUpperCase();
		
		let appendixTitleDefaultContent = '<h1 class="appendix-title">Type the title of your appendix here</h1>';
		
		if(store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
			appendixTitleDefaultContent = '<h1 class="appendix-title fw-normal">Optional descriptive title for the appendix</h1><br /><p>Type your appendix content here...</p>';
		}

		store.commit('paperEdit/appendices/ADD_TO_PAPER', {
			content: appendixTitleDefaultContent,	// this is the Title the user can change, don't confuse it with the Label like "Appendix A"
			uid: newUUID, 
		});

		// after adding just reclean them (to sync sort and title)
		PApS_CleanUp().then((cleanedAppendicesInPaper)=>{
			store.commit('paperEdit/appendices/IN_PAPER', cleanedAppendicesInPaper);
			
			store.dispatch('paperEdit/toc/generateContent').then(()=>{
				window.$vm.emitter.emit('globalToasterOpen',{
					textContent: 'Appendix added',
				});

				resolve(newUUID);
			});//e:dispatch:paperEdit/toc/generateContent
			
		});
	});//e:Promise
}