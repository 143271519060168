// OS_SaveContent
// Saves outline content in the Paper Organize Tab

import _forEach from 'lodash/forEach';
// import ApiS_MakeCall from '@/services/app/api/makeCall';
// import LzString from 'lz-string';
import PS_BuildReferencesInPaper from '@/services/paper/buildReferencesInPaper';
import PS_SaveDocument from '@/services/paper/saveDocument';
import store from '@/store';

export default () => {
	return new Promise((resolve) => {
		// console.log('OS_SaveContent');

		// before saving the paper document, update the Vuex store object
		if(window.$ckPaperOutline) {
			PS_BuildReferencesInPaper().then(()=>{
				store.commit('loaders/ADD_ID', 'App');

				let outlineHtml = window.$ckPaperOutline.getData();
				// console.log('outlineHtml');
				// console.log(outlineHtml);

				// experimental, remove raw p tags
				// outlineHtml = outlineHtml.replaceAll('<p>', '');
				// outlineHtml = outlineHtml.replaceAll('</p>', '');

				store.commit('paperEdit/outline/FULL_HTML', outlineHtml);

				PS_SaveDocument({
					isStealthSave: true,
					message: 'Saved Organize tab',
				}).then(()=>{
					store.commit('paperEdit/outline/SET_IS_UNSAVED', false);
					
					window.$vm.emitter.emit('globalToasterOpen',{
						textContent: 'Outline changes saved',
					});
					store.commit('loaders/REMOVE_ID', 'App');
					return resolve();
				});
				
				/*
				// dont save the Paper here, the tab isn't open and it causes weird problems. instead lets do a custom paper save that only replaces outline content!

				// write the initial request local logs
				store.dispatch('logs/write', {
					message: 'Saved Organize tab',
				});
				
				// decompress and parse the paper content (editor tab is closed but i have a copy in the store)
				let decompressedPaper = LzString.decompressFromUTF16(store.state.paperEdit.encryptedPaperLZString);
				let parsedHtml = new DOMParser().parseFromString(decompressedPaper, 'text/html');

				// change only the <outline> data
				let outlineElements = parsedHtml.getElementsByTagName("outline");
				if(outlineElements[0]){
					outlineElements[0].setAttribute('data', window.btoa(encodeURIComponent(outlineHtml)));
				}

				// recompress paper content
				let compressedPaperContent = LzString.compressToUTF16(parsedHtml.documentElement.innerHTML)
				let encryptedPaper = encodeURIComponent(compressedPaperContent);

				ApiS_MakeCall({
					contentType: 'application/x-www-form-urlencoded',
					body: 'content=' + encryptedPaper,
					method: 'PUT',
					params: {
						message: 'Saved Organize tab',
					},
					responseType: 'json',
					url: 'v3/Paper',
				}).then((responseData)=>{
					// console.log('responseData');
					// console.log(responseData);

					store.commit('paperEdit/outline/SET_IS_UNSAVED', false);
						
					window.$vm.emitter.emit('globalToasterOpen',{
						textContent: 'Outline changes saved',
					});
					store.commit('loaders/REMOVE_ID', 'App');
					return resolve();

				});//e:ApiS_MakeCall
				*/
			});//e:PS_BuildReferencesInPaper
		} else {
			return resolve();
		}
	});//e:Promise
}