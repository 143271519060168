/*
- PCKS_WrapCitation
-- https://perrla.monday.com/boards/1892814374/pulses/3040814893
-- checks the character before and after the citation widget for parentiesis 
-- add them if they have been removed, outside the widget
*/

import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import config from '@/config';
import store from '@/store';

export default ($opts) => {
	return new Promise((resolve) => {
		// console.log('PCKS_WrapCitation');
		
		// wrap the citation you just inserted with ( )
		let $citationNode = null;

		// loop through ckeditor instance
		_forEach(CKEDITOR.instances, (ckInstance)=>{
			// look for a group citation first - do i need this here?
			if(_has($opts, 'groupUniqueID') && $opts.groupUniqueID){
				$citationNode = ckInstance.document.findOne('span[data-group-unique-id="' + $opts.groupUniqueID.toUpperCase() + '"]');
			}
			if($citationNode){
				return false;	// stop looking when found
			} else {
				// look for regular citations second
				$citationNode = ckInstance.document.findOne('span[data-citation-unique-id="' + $opts.citationUniqueID + '"]');
				if($citationNode){
					return false;	// stop looking when found
				}
			}
		});//e:forEach:ckInstance

		// if the citation element was not found, resolve now
		if(!$citationNode){
			return resolve();
		}

		// grab some generic properties that you can use after the format split
		let beforeText = '';
		let citationPrevious;
		let citationParent = $citationNode.getParent();
		if(citationParent){
			citationPrevious = citationParent.getPrevious();
			
			if(citationPrevious && citationPrevious.type === CKEDITOR.NODE_TEXT){
				beforeText = citationPrevious.getText() || '';

			}//e:if
		}//e:if:citationParent

		// the citation node should have been identified by now
		if($citationNode){
			if(store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
				// turabian 9 doesnt have any citation wrapping

				// there is a chance this was created using the new citation create context menu, if so there will be a # before this citation
				if(beforeText && beforeText.charAt(beforeText.length - 1) === '#'){
					let $elToInsert = new CKEDITOR.dom.element('span');
					$elToInsert.setText(beforeText.slice(0, -1));
					$elToInsert.replace(citationPrevious);
				}

				return resolve();

			} else if(store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7 || store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9){
				// apa7 or mla9 - citations wrap
				
				// this feature will come from a <meta> tag in the paper content
				if(store.state.paperEdit.meta.UseNewCitationMode){
					if(!_has($opts, 'citationUniqueID')){
						return resolve();
					}

					if(citationParent){
						if(
							beforeText === '' ||
							(beforeText && beforeText.charAt(beforeText.length - 1) !== '(')
						){
							// i need to add an open bracket before the citation
							let bracketElement = new CKEDITOR.dom.text('(');
							bracketElement.insertBefore(citationParent);
						}
						
						// now check after this citation
						let citationNext = citationParent.getNext();
						if(citationNext && citationNext.type === CKEDITOR.NODE_TEXT){
							let afterText = citationNext.getText() || '';
							if(afterText === ''  || (afterText && afterText.charAt(0) !== ')')){
								// i need to add an close bracket after the citation
								let bracketElement = new CKEDITOR.dom.text(')');
								bracketElement.insertAfter( citationParent );
							}
						} else {
							let bracketElement = new CKEDITOR.dom.text(')');
							bracketElement.insertAfter( citationParent );
						}
						return resolve();
					}

				} else {
					return resolve();

				}//e:if:isCitationOutputV2
				
			}//e:if:else:turabian9

		}//e:if:$citationNode

	});//e:Promise
}