// PS_AddReferenceToPaper
// happens when adding a reference from the library, or when creating a new reference in a paper

import PAnS_AddUpdate from '@/services/paper/annotations/addUpdate';
import PS_UpdateReferenceObject from '@/services/paper/updateReferenceObject';
import RS_GetValueOutput from '@/services/reference/getValueOutput';
import store from '@/store';

export default ($opts) => {
	return new Promise((resolve) => {
		store.commit('paperEdit/REFERENCE_ADD_TO_PAPER', $opts.reference.referenceUniqueID.toUpperCase());
						
		// craft a message that will eventually make it's way to saveDocument
		let referenceDisplayValue = RS_GetValueOutput({
			displayFormatVersionId: store.state.customer.preferredFormatVersionID,
			referenceData: $opts.reference,
		});

		PS_UpdateReferenceObject({
			saveMessage: 'Added reference from library - ' + referenceDisplayValue,
		}).then(()=>{
			if($opts.isDrawer){
				if(store.state.paperEdit.annotations.isInclude){
					PAnS_AddUpdate({
						content: '',
						referenceUniqueId: $opts.reference.referenceUniqueID.toUpperCase(),
					});
				}
			}

			// add reference to list of all customer papers
			let findPaperIndex = store.state.paperList.list.findIndex((paper) =>{
				return paper.paperID === $opts.paperID;
			});
			if(findPaperIndex != -1){
				// paper found, is this reference already in the list (assume APA7 / Genesis)
				if(!store.state.paperList.list[findPaperIndex].genesisReferences.includes($opts.referenceID)){
					store.commit('paperList/ADD_REFERENCE_ID_TO_PAPER', {
						paperIndex: findPaperIndex,
						referenceID: $opts.referenceID
					});
				}
			}
			
			return resolve();
			
		});//e:PS_UpdateReferenceObject

	});//e:Promise
}
